.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.router-link,
.no-underline {
  text-decoration: none;
}
h2.title{
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  padding-left:12px;
  
 
}
h4{ font-family: Roboto;}
p {
  font-family: Roboto;
}

.img-responsive{
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.wrapper {
  position: relative;
  height: 0;
  padding-top: calc(var(--height) / var(--width) * 100%);
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 500px 640px;
  -webkit-animation: placeHolderShimmer 1.8s linear infinite forwards;
          animation: placeHolderShimmer 1.8s linear infinite forwards;
}

.wrapper__img-responsive {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto; 
}
.imageRoot {
  max-width: calc(var(--maxWidth) * 1px);
}
.img-sw-photo{
  width: auto;
  height:auto;
  max-height:20rem;
  max-width:100%;
 
}
.img-loading{
  width: 40rem;
  height:100%;
  max-height:40rem;
  max-width:100%;
 
}
/* Pulse class and keyframe animation */
#pulse{
	-webkit-animation: pulse linear 3s infinite;
	animation: pulse linear 1s infinite;
}
@-webkit-keyframes pulse {
  0% { -webkit-transform: scale(1); transform: scale(1); }
  50% { -webkit-transform: scale(1.1); transform: scale(1.1); }
  100% { -webkit-transform: scale(1); transform: scale(1); } 
}
@keyframes pulse {
  0% { -webkit-transform: scale(1); transform: scale(1); }
  50% { -webkit-transform: scale(1.1); transform: scale(1.1); }
  100% { -webkit-transform: scale(1); transform: scale(1); } 
} 
@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
} 
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.root{
    -webkit-flex-grow: 1;
            flex-grow: 1
}




*{
    box-sizing: border-box;
}

body {
    margin: 0;
}

/* Create two equal columns that floats next to each other */
.columnL {
    float: left;
    text-align: right;
    width: 50%;
    padding: 10px;
    
}
.columnR {
    float: right;
    text-align: left;
    width: 50%;
    padding: 10px;
    
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}
.grid-container {
    display: grid;
    grid-gap: 50px 100px;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
    .column {
        width: 100%;
    }
}
.bb {
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.b--black-10 {
    border-color: rgba(0, 0, 0, .1);
}

.db {
    display: block;
}

.dt {
    display: table;
}

.dtc {
    display: table-cell;
}

.fw4 {
    font-weight: 400;
}

.fw6 {
    font-weight: 600;
}

.lh-title {
    line-height: 1.25;
}

.link {
    text-decoration: none;
    transition: color .15s ease-in;
}

.link:link, .link:visited {
    transition: color .15s ease-in;
}

.link:hover {
    transition: color .15s ease-in;
}

.link:active {
    transition: color .15s ease-in;
}

.link:focus {
    transition: color .15s ease-in;
    outline: 1px dotted currentColor;
}

.mw6 {
    max-width: 32rem;
}

.w3 {
    width: 4rem;
}

.w-100 {
    width: 100%;
}

.black-60 {
    color: rgba(0, 0, 0, .6);
}

.black {
    color: #000;
}

.blue {
    color: #408bc9;
}

.pl2 {
    padding-left: .5rem;
}

.pb2 {
    padding-bottom: .5rem;
}

.ml0 {
    margin-left: 0;
}

.mb0 {
    margin-bottom: 0;
}

.mt2 {
    margin-top: .5rem;
}

.mv0 {
    margin-top: 0;
    margin-bottom: 0;
}

.f6 {
    font-size: .875rem;
}

.center {
    margin-right: auto;
    margin-left: auto;
}

.clip {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}

.v-top {
    vertical-align: top;
}

.dim {
    opacity: 1;
    transition: opacity .15s ease-in;
}

.dim:hover, .dim:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
}

.dim:active {
    opacity: .8;
    transition: opacity .15s ease-out;
}

@media screen and (min-width: 30em) {
    .f5-ns {
        font-size: 1rem;
    }
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.router-link,
.no-underline {
  text-decoration: none;
}
h2.title{
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  padding-left:12px;
 
}
h4{ font-family: Roboto;}
p {
  font-family: Roboto;
}

.img-responsive{
  width: 100%;
  height:auto;
}
.img-sw-photo{
  width: auto;
  height:auto;
  max-height:20rem;
  max-width:100%;
}

/* Pulse class and keyframe animation */
#pulse{
	-webkit-animation: pulse linear 3s infinite;
	animation: pulse linear 1s infinite;
}
@-webkit-keyframes pulse {
  0% { -webkit-transform: scale(1); transform: scale(1); }
  50% { -webkit-transform: scale(1.1); transform: scale(1.1); }
  100% { -webkit-transform: scale(1); transform: scale(1); } 
}
@keyframes pulse {
  0% { -webkit-transform: scale(1); transform: scale(1); }
  50% { -webkit-transform: scale(1.1); transform: scale(1.1); }
  100% { -webkit-transform: scale(1); transform: scale(1); } 
}

/* .AW-root: {
        width: '100%'
}
.AW-flex: {
        flex: 1
}
.AW-appBarFirstButton: {
        marginLeft: -12,
        marginRight: 12
}
    
.AW-appBarLastButton: {
        marginLeft: 12,
        marginRight: -12
    }
    
.AW-appBarPic: {
        width: "120px",
        height: '45px'
    }
    .AW-label: {
        textTransform: 'capitalize',
    } */



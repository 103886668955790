*{
    box-sizing: border-box;
}

body {
    margin: 0;
}

/* Create two equal columns that floats next to each other */
.columnL {
    float: left;
    text-align: right;
    width: 50%;
    padding: 10px;
    
}
.columnR {
    float: right;
    text-align: left;
    width: 50%;
    padding: 10px;
    
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}
.grid-container {
    display: grid;
    grid-gap: 50px 100px;
    grid-template-columns: auto auto auto;
    background-color: #2196F3;
    padding: 10px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8);
    padding: 20px;
    font-size: 30px;
    text-align: center;
  }

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media (max-width: 600px) {
    .column {
        width: 100%;
    }
}
.bb {
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.b--black-10 {
    border-color: rgba(0, 0, 0, .1);
}

.db {
    display: block;
}

.dt {
    display: table;
}

.dtc {
    display: table-cell;
}

.fw4 {
    font-weight: 400;
}

.fw6 {
    font-weight: 600;
}

.lh-title {
    line-height: 1.25;
}

.link {
    text-decoration: none;
    transition: color .15s ease-in;
}

.link:link, .link:visited {
    transition: color .15s ease-in;
}

.link:hover {
    transition: color .15s ease-in;
}

.link:active {
    transition: color .15s ease-in;
}

.link:focus {
    transition: color .15s ease-in;
    outline: 1px dotted currentColor;
}

.mw6 {
    max-width: 32rem;
}

.w3 {
    width: 4rem;
}

.w-100 {
    width: 100%;
}

.black-60 {
    color: rgba(0, 0, 0, .6);
}

.black {
    color: #000;
}

.blue {
    color: #408bc9;
}

.pl2 {
    padding-left: .5rem;
}

.pb2 {
    padding-bottom: .5rem;
}

.ml0 {
    margin-left: 0;
}

.mb0 {
    margin-bottom: 0;
}

.mt2 {
    margin-top: .5rem;
}

.mv0 {
    margin-top: 0;
    margin-bottom: 0;
}

.f6 {
    font-size: .875rem;
}

.center {
    margin-right: auto;
    margin-left: auto;
}

.clip {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
}

.v-top {
    vertical-align: top;
}

.dim {
    opacity: 1;
    transition: opacity .15s ease-in;
}

.dim:hover, .dim:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
}

.dim:active {
    opacity: .8;
    transition: opacity .15s ease-out;
}

@media screen and (min-width: 30em) {
    .f5-ns {
        font-size: 1rem;
    }
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.router-link,
.no-underline {
  text-decoration: none;
}
h2.title{
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  padding-left:12px;
  
 
}
h4{ font-family: Roboto;}
p {
  font-family: Roboto;
}

.img-responsive{
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.wrapper {
  position: relative;
  height: 0;
  padding-top: calc(var(--height) / var(--width) * 100%);
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 500px 640px;
  animation: placeHolderShimmer 1.8s linear infinite forwards;
}

.wrapper__img-responsive {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  height: auto; 
}
.imageRoot {
  max-width: calc(var(--maxWidth) * 1px);
}
.img-sw-photo{
  width: auto;
  height:auto;
  max-height:20rem;
  max-width:100%;
 
}
.img-loading{
  width: 40rem;
  height:100%;
  max-height:40rem;
  max-width:100%;
 
}
/* Pulse class and keyframe animation */
#pulse{
	-webkit-animation: pulse linear 3s infinite;
	animation: pulse linear 1s infinite;
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); } 
} 
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.root{
    flex-grow: 1
}




.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.router-link,
.no-underline {
  text-decoration: none;
}
h2.title{
  font-family: Roboto;
  font-weight: normal;
  font-style: italic;
  padding-left:12px;
 
}
h4{ font-family: Roboto;}
p {
  font-family: Roboto;
}

.img-responsive{
  width: 100%;
  height:auto;
}
.img-sw-photo{
  width: auto;
  height:auto;
  max-height:20rem;
  max-width:100%;
}

/* Pulse class and keyframe animation */
#pulse{
	-webkit-animation: pulse linear 3s infinite;
	animation: pulse linear 1s infinite;
}
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); } 
}

/* .AW-root: {
        width: '100%'
}
.AW-flex: {
        flex: 1
}
.AW-appBarFirstButton: {
        marginLeft: -12,
        marginRight: 12
}
    
.AW-appBarLastButton: {
        marginLeft: 12,
        marginRight: -12
    }
    
.AW-appBarPic: {
        width: "120px",
        height: '45px'
    }
    .AW-label: {
        textTransform: 'capitalize',
    } */

